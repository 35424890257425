<template>
  <div id="add-chairman-data">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          {{ pageTitle }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-container style="overflow: hidden">
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="submitData"
          >
            <v-card-text>
              <v-container>
                <!-- <p class="notes-text1">
                  <span class="text-danger">*</span> indicates required field
                </p> -->
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6 class="mycard-text1 fsize-4">
                        <span class="text-danger">*</span> Member Type
                      </h6>
                    </label>
                    <v-select
                      :reduce="(option) => option.value"
                      v-model="MemberTypeId"
                      :items="MemberTypeIdOptions"
                      :rules="MemberTypeIdRules"
                      dense
                      required
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-if="MemberTypeId == 1">
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6 class="mycard-text1 fsize-4">
                        <span class="text-danger">*</span> Membership ID
                      </h6>
                    </label>
                    <v-text-field
                      v-model="MembershipId"
                      :rules="MembershipIdRules"
                      placeholder="Membership ID"
                      hint="Membership id for chairman"
                      append-outer-icon="mdi-magnify-plus"
                      :loading="MembershipIdLoading"
                      @click:append-outer="getMemberDetails"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6 class="mycard-text1 fsize-4">Chairman Photo</h6>
                    </label>
                    <br />
                    <img
                      :src="MemberData.MemberImage"
                      width="100"
                      v-if="MemberData.Fullname != ''"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6 class="mycard-text1 fsize-4">Chairman Details</h6>
                    </label>
                    <br />
                    <h5>{{ MemberData.Fullname }}</h5>
                    <h5>{{ MemberData.MemberDesignation }}</h5>
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-if="MemberTypeId == 2">
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6 class="mycard-text1 fsize-4">
                        <span class="text-danger">*</span> Chairman Name
                      </h6>
                    </label>
                    <v-text-field
                      v-model="MemberName"
                      :rules="MemberNameRules"
                      placeholder="Chairman name"
                      hint="Enter chairman name"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6 class="mycard-text1 fsize-4">
                        Description <small> (optional)</small>
                      </h6>
                    </label>
                    <v-text-field
                      v-model="MemberDetails"
                      :rules="MemberDetailsRules"
                      placeholder="Chairman details"
                      hint="Enter chairman details"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-4">
                      Upload Image
                      <small> (optional)</small>
                      <p class="notes-text1" >(only jpg, png image files are accepted)</p>
                    </h6>
                    <v-file-input
                      :clearable="false"
                      truncate-length="25"
                      accept="image/png, image/jpeg"
                      placeholder="Upload Chairman Photo"
                      prepend-icon="mdi-account-box"
                      append-outer-icon="mdi-delete"
                      v-model="UploadImage1"
                      @change="addImage(1)"
                    >
                      <template v-slot:append-outer>
                        <v-icon color="red" @click="deleteImage(1)">
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col align="center" cols="12" sm="6" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-4">Image Edit Options</h6>
                    <br />
                    <div class="actions">
                      <b-button-group vertical class="mr-3 mb-3">
                        <v-btn
                          fab
                          dark
                          small
                          color="indigo"
                          @click.prevent="flip(true, false)"
                        >
                          <v-icon>fa fa-caret-right</v-icon>
                        </v-btn>
                      </b-button-group>

                      <b-button-group vertical class="mr-3 mb-3">
                        <v-btn
                          fab
                          dark
                          small
                          color="indigo"
                          @click.prevent="flip(false, true)"
                        >
                          <v-icon>fa fa-caret-up</v-icon>
                        </v-btn>
                      </b-button-group>

                      <b-button-group vertical class="mr-3 mb-3">
                        <v-btn
                          fab
                          dark
                          small
                          color="indigo"
                          @click.prevent="rotate(90)"
                        >
                          <v-icon>fa fa-redo</v-icon>
                        </v-btn>
                      </b-button-group>

                      <b-button-group vertical class="mr-3 mb-3">
                        <v-btn
                          fab
                          dark
                          small
                          color="indigo"
                          @click.prevent="rotate(-90)"
                        >
                          <v-icon>fa fa-undo</v-icon>
                        </v-btn>
                      </b-button-group>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-4">
                      Uploaded Image will Appear Here
                    </h6>
                    <cropper
                      ref="cropper"
                      :src="MemberImage.ImageUrl"
                      :stencil-props="{
                        handlers: {},
                        movable: false,
                        scalable: false,
                      }"
                      :stencil-size="StencilSize"
                      image-restriction="stencil"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-if="MemberTypeId != ''">
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <v-btn
                      @click="closePrompt"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#a52a2a"
                      class="font-size-h6 fsize-3 mr-3 my-3 white--text"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      :disabled="!valid1"
                      @click.prevent="submitData"
                      :loading="SubmitFlag"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#1db954"
                      class="font-size-h6 fsize-3 mr-3 my-3 white--text"
                    >
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-container>
      </v-sheet>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    pageTitle: {
      type: String,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      valid1: true,

      LoadingFlag: false,
      SubmitFlag: false,

      FacultyTypeIdRules: [(v) => !!v || "Faculty type is required"],
      FacultyTypeId: "",
      FacultyTypeIdOptions: [],
      FacultyTypeIdOptionsLoading: false,

      MemberTypeIdRules: [(v) => !!v || "Member type is required"],
      MemberTypeId: "",
      MemberTypeIdOptions: [
        { value: "", text: "- Select -" },
        { value: 1, text: "Member" },
        { value: 2, text: "Non Member" },
      ],
      MemberTypeIdOptionsLoading: false,

      MembershipId: "",
      MembershipIdRules: [(v) => !!v || "Membership id is required"],
      MembershipIdLoading: false,

      MemberName: "",
      MemberNameRules: [(v) => !!v || "Chairman name is required"],

      MemberDetails: "",
      MemberDetailsRules: [
        // (v) => !!v || "Faculty details required"
      ],

      MemberData: {},

      image: {
        src: "",
        type: null,
      },

      current_photo: "",

      UploadImage1: {},
      MemberImage: { src: null },

      StencilSize: { width: 250, height: 250 },
    };
  },
  computed: {},
  watch: {
    FacultyTypeIdOptions: function () {
      console.log("watch FacultyTypeIdOptions");
      this.LoadingFlag = false;
      this.FacultyTypeIdOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      this.closePrompt();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt(member = {}) {
      console.log("closePrompt called");
      console.log({ member });
      this.$emit("hideDialog", member);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      //   this.getFacultyTypeIdOptions();
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    flip(x, y) {
      console.log("flip x=" + x + ", y=" + y);
      this.$refs.cropper.flip(x, y);
    },
    rotate(angle) {
      console.log("rotate=" + angle);
      this.$refs.cropper.rotate(angle);
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.UploadImage1;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.MemberImage.src) {
            URL.revokeObjectURL(this.MemberImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.MemberImage = {
            ImageName: file.name,
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file,
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.MemberImage = {
            ImageName: null,
            ImageType: null,
            ImageUrl: null,
            UploadedImage: null,
          };
          this.UploadImage1 = {};
          break;

        default:
          break;
      }
    },
    getFacultyTypeIdOptions() {
      console.log("getFacultyTypeIdOptions called");
      this.FacultyTypeIdOptionsLoading = true;
      var selectbox1_source = "FacultyTypeId";
      var selectbox1_destination = "FacultyTypeIdOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        CategoryMainId: 17,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getMemberDetails() {
      console.log("getMemberDetails is called");

      var MembershipId = this.MembershipId;
      console.log("MembershipId=" + MembershipId);

      if (MembershipId != "") {
        this.MembershipIdLoading = true;
        this.MemberData = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/search";
        var upload = {
          UserInterface: 2,
          MembershipId: MembershipId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SubmitFlag = false;
            thisIns.StartupLoadingFlag = false;
            thisIns.MembershipIdLoading = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.MemberData = records;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.MembershipIdLoading = false;
          });
      } else {
        var message = "";
        if (MembershipId == "") {
          message += "Membership Id should not be empty.";
        }
        this.toast("error", message);
      }
    },
    submitData() {
      console.log("submitData is called");
      var message = "";
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        // Get Member Type details

        var id2 = this.MemberTypeId;
        var index2 = this.MemberTypeIdOptions.map((e) => e.value).indexOf(id2);
        console.log("id2=" + id2 + ", index2=" + index2);
        var text2 = this.MemberTypeIdOptions[index2].text;
        console.log("text2=" + text2);

        if (id2 == 1) {
          var MemberData = this.MemberData;
          console.log({ MemberData });
          var tempData = {
            MembershipId: this.MembershipId,
            Fullname: MemberData.Fullname,
            MemberImage: MemberData.MemberImage,
            MemberDesignation: MemberData.MemberDesignation,
          };
          console.log({ tempData });
          this.closePrompt(tempData);
        } else {
          const { canvas } = this.$refs.cropper.getResult();
          if (canvas) {
            var FileType = this.MemberImage.ImageType;
            var ImageName = this.MemberImage.ImageName;
            console.log({ FileType });
            console.log({ ImageName });
            //   const form = new FormData();
            canvas.toBlob((blob) => {
              // formData.append("ProfilePicture", blob, "logo.png");
              var tempData = {
                MembershipId: this.MembershipId,
                Fullname: this.MemberName,
                MemberImage: canvas.toDataURL(),
                MemberDesignation: this.MemberDetails,
                UploadedImage: blob,
                UploadedImageName: ImageName,
              };
              console.log({ tempData });
              this.closePrompt(tempData);
              // Perhaps you should add the setting appropriate file format here
            }, FileType);
          } else {
            message += "Kindly select image. ";
            // this.toast("error", message, true);
            var tempData = {
              MembershipId: this.MembershipId,
              Fullname: this.MemberName,
              MemberImage: this.MemberImage.src,
              MemberDesignation: this.MemberDetails,
            };
            console.log({ tempData });
            this.closePrompt(tempData);
          }
        }
      } else {
        if (validate1 == false) {
          message += "Kindly fill the required fields. ";
        }
        this.toast("error", message, true);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#add-chairman-data {
  .mycard-text1 {
    font-family: sans-serif;
  }
  .fsize-4 {
    font-size: 18px !important;
  }
  .notes-text1 {
    padding: 5px;
    margin-left: 2rem;
    font-size: 14px;
    font-family: sans-serif;
  }
  .fsize-3 {
    font-size: 16px !important;
  }
}
</style>